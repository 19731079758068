/**
 * 路由组件出口文件
 * yezi 2018年6月24日
 */
import React from 'react'

import Loadable from 'react-loadable';
import Loading from './widget/Loading';
import BasicForm from './forms/BasicForm';
import BasicTable from './tables/BasicTables';
import AdvancedTable from './tables/AdvancedTables';
import AsynchronousTable from './tables/AsynchronousTable';
// import Echarts from './charts/Echarts';
// import Recharts from './charts/Recharts';
// import Icons from './ui/Icons';
// import Buttons from './ui/Buttons';
// import Spins from './ui/Spins';
// import Modals from './ui/Modals';
// import Notifications from './ui/Notifications';
// import Tabs from './ui/Tabs';
// import Banners from './ui/banners';
// import Drags from './ui/Draggable';
// import Dashboard from './dashboard/Dashboard';
// import Gallery from './ui/Gallery';
// import BasicAnimations from './animation/BasicAnimations';
// import ExampleAnimations from './animation/ExampleAnimations';



// import AuthBasic from './auth/Basic';
// import RouterEnter from './auth/RouterEnter';
// import Cssmodule from './cssmodule';
// import MapUi from './ui/map';
// import QueryParams from './extension/QueryParams';



//首页
// import Dashboard from '../pages/Dashboard/Dashboard';


// import Smartquotation from '../pages/Smartquotation/Smartquotation';
//商品管理
// import GoodsManage from '../pages/GoodsManage/GoodsManage';
// import AddGoods from '../pages/GoodsManage/AddGoods';
// import Goodupdate from '../pages/GoodsManage/Goodupdate'
// import Goodsaddition from '../pages/GoodsManage/addition'
// import Rotation from '../pages/GoodsManage/Rotation'
//商品共享
// import Stocklist from '../pages/Stock/Stocklist'
// import Mystock from '../pages/Stock/Mystock'
// import SpecialOffer from '../pages/Stock/specialOffer'
// import Carts from '../pages/Stock/carts'
// import Ordersettlement from '../pages/Stock/Ordersettlement'
// import Comfirorder from '../pages/Stock/comfirorder'
//报价记录
// import Offerlist from '../pages/Offer/Offerlist'
// import Offerdetail from '../pages/Offer/Offerdetail'
//调价记录
// import TransferList from '../pages/Transfer/transferList'
// import Transferdetail from '../pages/Transfer/transferDetail'
// import SaleList from '../pages/Sales/saleList'
// import ShareOrderDetail from '../pages/Sales/ShareOrderDetail'

// import OrdersManage from '../pages/Orders/OrdersManage';
// import OrderDetail from '../pages/Orders/OrderDetail';
// import PwdSetting from '../pages/User/PwdSetting';
// import PersonSetting from '../pages/User/PersonSetting';
// import CompanySetting from '../pages/User/CompanySetting';
// import AccountSetting from '../pages/User/AccountSetting';
// export * from './dashboard'
// import MyCollection from "../pages/Autorepairmap/MyCollection";
// import AutoRepairDatabase from "../pages/Autorepairmap/AutoRepairDatabase";

// import TEST from '../components/input/CustomInput';
//智能epc
// import CheckVehicle from '../pages/Intelligence/checkVehicle'

// 按需加载组件 开始
// const Echarts = React.lazy(() => import('./charts/Echarts'));
// const Recharts = React.lazy(() => import('./charts/Recharts'));
// const Icons = React.lazy(() => import('./ui/Icons')) ;
// const Buttons = React.lazy(() => import('./ui/Buttons')) ;
// const Spins = React.lazy(() => import('./ui/Spins')) ;
// const Modals = React.lazy(() => import('./ui/Modals')) ;
// const Notifications = React.lazy(() => import('./ui/Notifications')) ;
// const Tabs = React.lazy(() => import('./ui/Tabs')) ;
// const Banners = React.lazy(() => import('./ui/banners')) ;
// const Drags = React.lazy(() => import('./ui/Draggable')) ;
const Gallery = React.lazy(() => import('./ui/Gallery')) ;
const BasicAnimations = React.lazy(() => import('./animation/BasicAnimations')) ;
const ExampleAnimations = React.lazy(() => import('./animation/ExampleAnimations')) ;
const AuthBasic = React.lazy(() => import('./auth/Basic')) ;
const RouterEnter = React.lazy(() => import('./auth/RouterEnter')) ;
const Cssmodule = React.lazy(() => import('./cssmodule')) ;
const MapUi = React.lazy(() => import('./ui/map')) ;

const QueryParams = React.lazy(() => import('./extension/QueryParams'));
// 首页
// const Dashboard = React.lazy(() => import('../pages/Dashboard/Dashboard'));
//智能报价
// const Smartquotation = React.lazy(() => import('../pages/Smartquotation/Smartquotation'));

// 商品管理
// const GoodsManage = React.lazy(() => import('../pages/GoodsManage/GoodsManage')) ;
// const AddGoods = React.lazy(() => import('../pages/GoodsManage/AddGoods'));
// const Goodupdate = React.lazy(() => import('../pages/GoodsManage/Goodupdate'));
// const Goodsaddition = React.lazy(() => import('../pages/GoodsManage/addition'));

// const Rotation = React.lazy(() => import('../pages/GoodsManage/Rotation'));

// 商品共享
// const Stocklist = React.lazy(() => import('../pages/Stock/Stocklist'));
// const Mystock = React.lazy(() => import('../pages/Stock/Mystock'));
// const SpecialOffer = React.lazy(() => import('../pages/Stock/specialOffer'));
// const Carts = React.lazy(() => import('../pages/Stock/carts'));
// const Ordersettlement = React.lazy(() => import('../pages/Stock/Ordersettlement'));
// const Comfirorder = React.lazy(() => import('../pages/Stock/comfirorder'));

// 报价记录
// const Offerlist = React.lazy(() => import('../pages/Offer/Offerlist'));
// const Offerdetail = React.lazy(() => import('../pages/Offer/Offerdetail'));

// 调价记录
// const TransferList = React.lazy(() => import('../pages/Transfer/transferList'));
// const Transferdetail = React.lazy(() => import('../pages/Transfer/transferDetail'));
// const SaleList = React.lazy(() => import('../pages/Sales/saleList'));
// const ShareOrderDetail = React.lazy(() => import('../pages/Sales/ShareOrderDetail'));

// const OrdersManage = React.lazy(() => import('../pages/Orders/OrdersManage'));
// const OrderDetail = React.lazy(() => import('../pages/Orders/OrderDetail'));
// const PwdSetting = React.lazy(() => import('../pages/User/PwdSetting'));
// const PersonSetting = React.lazy(() => import('../pages/User/PersonSetting'));
// const CompanySetting = React.lazy(() => import('../pages/User/CompanySetting'));
// const AccountSetting = React.lazy(() => import('../pages/User/AccountSetting'));
// const MyCollection = React.lazy(() => import('../pages/Autorepairmap/MyCollection'));
// const AutoRepairDatabase = React.lazy(() => import('../pages/Autorepairmap/AutoRepairDatabase'));
// const TEST = React.lazy(() => import('../components/input/CustomInput'));
// const CheckVehicle = React.lazy(() => import('../pages/Intelligence/checkVehicle'));

const Echarts = Loadable({
    loader: () => import('./charts/Echarts'),
    loading: Loading
})
const Recharts = Loadable({
    loader: () => import('./charts/Recharts'),
    loading: Loading
})
const Icons = Loadable({
    loader: () => import('./ui/Icons'),
    loading: Loading
})
const Buttons = Loadable({
    loader: () => import('./ui/Buttons'),
    loading: Loading
})
const Spins = Loadable({
    loader: () => import('./ui/Spins'),
    loading: Loading
})
const Modals = Loadable({
    loader: () => import('./ui/Modals'),
    loading: Loading
})
const Notifications = Loadable({
    loader: () => import('./ui/Notifications'),
    loading: Loading
})
const Tabs = Loadable({
    loader: () => import('./ui/Tabs'),
    loading: Loading
})
const Banners = Loadable({
    loader: () => import('./ui/banners'),
    loading: Loading
})
const Drags = Loadable({
    loader: () => import('./ui/Draggable'),
    loading: Loading
})
const ErrorBoundary = Loadable({
    loader: () => import('./ErrorBoundary'),
    loading: Loading
})

// 首页
const Dashboard = Loadable({
    loader: () => import('../pages/Dashboard/Dashboard'),
    loading: Loading
})

// 智能报价
const Smartquotation = Loadable({
    loader: () => import('../pages/Smartquotation/Smartquotation'),
    loading: Loading
})

// 商品管理
const GoodsManage = Loadable({
    loader: () => import('../pages/GoodsManage/GoodsManage'),
    loading: Loading
})
const AddGoods = Loadable({
    loader: () => import('../pages/GoodsManage/AddGoods'),
    loading: Loading
})
const Goodupdate = Loadable({
    loader: () => import('../pages/GoodsManage/Goodupdate'),
    loading: Loading
})
const Goodsaddition = Loadable({
    loader: () => import('../pages/GoodsManage/addition'),
    loading: Loading
})

const Rotation = Loadable({
    loader: () => import('../pages/GoodsManage/Rotation'),
    loading: Loading
})

// 商品共享
const ViewShare = Loadable({          //查看共享
    loader: () => import('../pages/Stock/ViewShare'),
    loading: Loading
})
const Stocklist = Loadable({
    loader: () => import('../pages/Stock/Stocklist'),
    loading: Loading
})
const Mystock = Loadable({
    loader: () => import('../pages/Stock/Mystock'),
    loading: Loading
})
const SpecialOffer = Loadable({
    loader: () => import('../pages/Stock/specialOffer'),
    loading: Loading
})
const Carts = Loadable({
    loader: () => import('../pages/Stock/carts'),
    loading: Loading
})
const Ordersettlement = Loadable({
    loader: () => import('../pages/Stock/Ordersettlement'),
    loading: Loading
})
const Comfirorder = Loadable({
    loader: () => import('../pages/Stock/comfirorder'),
    loading: Loading
})

// 特价清仓
const SpecialManage = Loadable({
    loader: () => import('../pages/Special/SpecialManage'),
    loading: Loading
})
// 报价记录
const Offerlist = Loadable({
    loader: () => import('../pages/Offer/Offerlist'),
    loading: Loading
})
const Offerdetail = Loadable({
    loader: () => import('../pages/Offer/Offerdetail'),
    loading: Loading
})

// 调价记录
const TransferList = Loadable({
    loader: () => import('../pages/Transfer/transferList'),
    loading: Loading
})
const Transferdetail = Loadable({
    loader: () => import('../pages/Transfer/transferDetail'),
    loading: Loading
})
const SaleList = Loadable({
    loader: () => import('../pages/Sales/saleList'),
    loading: Loading
})
const ShareOrderDetail = Loadable({
    loader: () => import('../pages/Sales/ShareOrderDetail'),
    loading: Loading
})
const OrdersManage = Loadable({
    loader: () => import('../pages/Orders/OrdersManage'),
    loading: Loading
})
const OrderDetail = Loadable({
    loader: () => import('../pages/Orders/OrderDetail'),
    loading: Loading
})

// 账号设置
const PwdSetting = Loadable({
    loader: () => import('../pages/User/PwdSetting'),
    loading: Loading
})
const PersonSetting = Loadable({
    loader: () => import('../pages/User/PersonSetting'),
    loading: Loading
})
const CompanySetting = Loadable({
    loader: () => import('../pages/User/CompanySetting'),
    loading: Loading
})
const AccountSetting = Loadable({
    loader: () => import('../pages/User/AccountSetting'),
    loading: Loading
})

const MyCollection = Loadable({
    loader: () => import('../pages/Autorepairmap/MyCollection'),
    loading: Loading
})
const AutoRepairDatabase = Loadable({
    loader: () => import('../pages/Autorepairmap/AutoRepairDatabase'),
    loading: Loading
})
const TEST = Loadable({
    loader: () => import('../components/input/CustomInput'),
    loading: Loading
})
const CheckVehicle = Loadable({
    loader: () => import('../pages/Intelligence/checkVehicle'),
    loading: Loading
})
// 智能EPC
const SmartEpc = Loadable({
    loader: () => import('../pages/SmartEPC/SmartEpc'),
    loading: Loading
})
// const InquiryDetail = Loadable({
//     loader: () => import('../pages/SmartEPC/InquiryDetail'),
//     loading: Loading
// })
//智能保险
const SmartInsurance = Loadable({
    loader: () => import('../pages/SmartInsurance/SmartInsurance'),
    loading: Loading
})
// 直供设置
const DirectSupplySet = Loadable({
    loader: () => import('../pages/DirectSupplySet'),
    loading: Loading
})
// 核价统计
const VerifyStatistics = Loadable({
    loader: () => import('../pages/VerifyStatistics'),
    loading: Loading
})
const InquiryRecord = Loadable({
    loader: () => import('../pages/InquiryRecord'),
    loading: Loading
})
const PersonSet = Loadable({
    loader: () => import('../pages/PersonSet'),
    loading: Loading
})
// 按需加载富文本配置
const WysiwygBundle = Loadable({
    loader: () => import('./ui/Wysiwyg'),
    loading: Loading,
});
const Index = Loadable({
    loader: () => import('../pages/Index'),
    loading: Loading,
})
// 透明修 询价记录
const Transparent = Loadable({
    loader: () => import('../pages/Transparent'),
    loading: Loading,
})
// 透明性 询价订单
const RepairOrder = Loadable({
    loader: () => import('../pages/RepairOrder'),
    loading: Loading,
})
//透明修订单
const touMingXiuOrder = Loadable({
    loader: () => import('../pages/touMingXiuOrder'),
    loading: Loading,
})
// 智配宝订单
const TransparantOrder = Loadable({
    loader: () => import('../pages/TransparantOrder'),
    loading: Loading,
})
// 智配宝
const WisDom = Loadable({
    loader: () => import('../pages/WisDom'),
    loading: Loading,
})

// 满意配
const accessory = Loadable({
    loader: () => import('../pages/accessory'),
    loading: Loading,
})
// 买家管理
const userManage = Loadable({
    loader: () => import('../pages/userManage'),
    loading: Loading,
})

// 数据中心
const dataCenter = Loadable({
    loader: () => import('../pages/dataCenter'),
    loading: Loading,
})
// 物损
const WuSun = Loadable({
    loader: () => import('../pages/WuSun/SmartInsurance'),
    loading: Loading
})
const banner = Loadable({
    loader: () => import('../pages/banner'),
    loading: Loading,
})

const jiansunInfo = Loadable({
    loader: () => import('../pages/jiansunListInfo'),
    loading: Loading,
})
export default {
    BasicForm,ErrorBoundary,Transparent,WisDom,RepairOrder,TransparantOrder,dataCenter,banner,WuSun,
     BasicTable, AdvancedTable, AsynchronousTable,ShareOrderDetail,ViewShare, Index,
    Echarts, Recharts, Icons, Buttons, Spins, Modals, Notifications,SmartInsurance,
    Tabs, Banners, Drags, Dashboard, Gallery, BasicAnimations, VerifyStatistics,DirectSupplySet,
    ExampleAnimations, AuthBasic, RouterEnter, WysiwygBundle,PersonSet,
    Cssmodule, MapUi, QueryParams,SmartEpc,InquiryRecord,
    Smartquotation, GoodsManage,
    PwdSetting,PersonSetting,CompanySetting,AccountSetting,OrdersManage,OrderDetail,
    AddGoods,Goodupdate,Rotation,Offerlist,Stocklist,Mystock,Offerdetail,Goodsaddition,
    MyCollection,AutoRepairDatabase,CheckVehicle,SpecialOffer,Carts,TransferList,Transferdetail,
    Ordersettlement,Comfirorder,SaleList,SpecialManage,touMingXiuOrder,accessory,userManage,
    TEST,jiansunInfo

}
