//测试
//吴松接口的port
// var url='http://139.196.16.229:10000'
// var url3='http://139.196.16.229'
// var url='http://192.168.0.122:10000'
//陈先虎接口的port
// var url1='http://139.196.16.229:9999'
// var url2='http://139.196.16.229:8989'
//正式
var url='http://148.70.137.178:10000'
// var url3='http://148.70.137.178'
// // var url='http://192.168.0.122:10000'
// //陈先虎接口的port
// var url1='http://148.70.137.178:9999'
// var url2='http://148.70.137.178:8989'

// 最新接口
// let url = 'http://119.3.173.23:10000'
// let url3 = 'http://119.3.173.23'

// let url1 = 'http://119.3.173.23:9999'
// let url2 = 'http://119.3.173.23:8989'

// let url = 'http://49.234.130.43:10000'
let url3 = 'http://49.234.130.43'
let url1 = 'http://49.234.130.43:9999'
let url2 = 'http://49.234.130.43:8989'
let url4 = 'http://49.234.130.43:9999'
let url5 = 'http://49.234.130.43:8810'
let url6 = 'http://49.234.130.43:9800'
let url7 = 'http://49.234.130.43:9077'
let url9777='http://49.234.130.43:9777'
let url9300='http://49.234.130.43:9300'
let url9801='http://49.234.130.43:9801'
//  后台管理
const url10 = 'http://49.234.130.43:9110'
let url11 = 'http://148.70.137.178:9001'
// let url4 = 'http://49.234.130.43:8888'

// 8888
// let url1 = 'http://49.234.130.43:8888'
// let url2 = 'http://49.234.130.43:8888'
// let url4 = 'http://49.234.130.43:8888'
if(1==1){
    url1=url10;
    url2=url10;
    url3=url10;
    url4=url10;
    url5=url10;
    url6=url10;
    url7=url10;
    url9777=url10;
    url9300=url10;
    url9801=url10;
}
export const URL_test = url2+ '/test';
//登陆
export const URL_login = url+ '/api/login';
export const URL_hu_login = url1 + '/login'
//注册
export const URL_register = url1+ '/register';

//忘记密码
export const URL_reset_password = url+ '/api/reset_password';

//修改密码
export const URL_modify_password =  '/api/modify_password';

//发送短信  /api/get_verification_code/{mobile}
export const URL_get_verification_code = '/api/get_verification_code/';
//注册公司

export const URL_vendor_account_profile_company = '/api/vendor/account/profile/company';

//根据名字查询公司

export const URL_vendor_account_query_company = '/api/vendor/account/query/company';


//图片解析
export const URL_vin_screenshot_analysis =url+'/api/vin/screenshot/analysis'
//获取autogroup
export const URL_vin_find_autogroups =url+'/api/vin/find/autogroups'

// 语音报价 启动标准化
export const URL_parts_text_to_stdname = url1 + '/text_to_stdname'
// 调货按钮
export const URL_shop_change = url1 + '/shop_change'
//零件识别接口图片解析
export const URL_parts_screenshot_analysis =url+'/api/parts/screenshot/analysis'
//昵称获取标准名:
export const URL_std_for_stdname =url1+'/std_for_stdname'
//订单中心
export const URL_ai_order_list =url1+'/api/ai/order/list'
//获取车型的主要特征
export const URL_vin_find_auto =url+'/api/vin/find/auto'
//截屏报价
export const URL_id_and_std_search =url1+'/id_and_std_search'
//修改报价
export const URL_modify_price=url1+'/modify_price'
//共享的查询
export const URL_search_sku =url1+'/search_sku'
//对外库存共享
export const URL_share_for_other =url1+'/share_for_other'
//
export const URL_share_for_price =url1+'/share_for_price'
//向我共享
export const URL_share_for_me =url4+'/share_for_me'
// 查看共享
export const URL_share_search = url4+'/new_search'
export const URL_look_share_new = url4+'/look_share_new'

// //
// export const URL_share_for_me_connect_seller =url1+'/share_for_meconnect_seller'
//商品列表
export const URL_api_parts_sku_list=url+'/api/parts/sku/list'


//商品设置价格修改
export const URL_api_goods_manage = url1+'/goods_manage'

// 特价设置
export const URL_special_price_new = url4 + '/special_price'
export const URL_api_special_price = url1 + '/special_price'

//商品列表上下架
export const URL_api_parts_sku_onlineParts=url+'/api/parts/sku/onlineParts'
//商品列表删除
export const URL_api_parts_sku_delete=url+'/api/parts/sku/delete'
//商品新增
export const URL_api_parts_sku_getMarker=url+'/api/parts/sku/getMarker'
//商品上传excel
export const URL_api_parts_sku_importPartSKu=url+'/api/parts/sku/importPartSKu'
//下载模板
export const URL_excel_template_partsku=url3+'/excel/template_partsku.xlsx'
//模糊查询零件
export const URL_api_parts_sku_getStdNameList=url+'/api/parts/sku/getStdNameList'
//获取配件品质
export const URL_api_parts_sku_getConfigQualitys=url+'/api/parts/sku/getConfigQualitys'
//获取质保期限
export const URL_api_parts_sku_getConfigWarrantys=url+'/api/parts/sku/getConfigWarrantys'
//商品保存提交
export const URL_api_parts_sku_save=url+'/api/parts/sku/save'
//商品图片的提交
export const URL_api_parts_sku_uploadPartImg=url+'/api/parts/sku/uploadPartImg'
//新增商品获取picId
export const URL_api_parts_sku_getPicId=url+'/api/parts/sku/getPicId'
//修改商品的详情页接口
export const URL_api_parts_sku_info=url+'/api/parts/sku/info'
//提交修改
export const URL_api_parts_sku_update=url+'/api/parts/sku/update'
//个人设置页面
export const URL_api_vendor_account_info=url+'/api/vendor/account/info'
//公司设置
export const URL_api_vendor_account_companyInfo=url+'/api/vendor/account/companyInfo'
//修改密码
export const URL_api_reset_password=url+'/api/reset_password'
//短信验证码
export const URL_api_get_verification_code=url+'/api/get_verification_code'

// 智能报价第三步获取报价
export const URL_gid_std_for_smartprice = url1+'/gid_std_for_price'
//dashboart
export const URL_get_dashboard = url1+ '/dashboard'
//品牌车型
export const URL_Brand_models =url2+'/brand_maker';

export const URL_Model_Stdoe =url2+'/model_stdoe';

export const URL_Price_cube = url2+'/price_cube';

export const URL_Part_Adaper = url2+'/part_adapter'

//报价中心

export const URL_Offer_record = url1+'/offer_record'

//报价详情
export const URL_0ffer_history = url1+'/offer_history'

// excel表格上传
export const URL_gid_std_for_price = url1+'/gid_std_for_price'
export const URL_share_exeal = url1+'/share_for_me'
export const URL_special_exeal = url1+ '/special_price'
//订单中心

export const URL_Order_list = url1+'/order_list'

export const URL_Order_center = url1+'/order_center'

//汽修地图

export const URL_Auto_repair = url1+'/repair_shop'

export const URL_my_favorite_repair_shop = url1+'/my_favorite_repair_shop'

//查询marker
export const URL_api_parts_sku_searchMaker=url+'/api/parts/sku/searchMaker'
//查询model
export const URL_api_parts_sku_searchModel=url+'/api/parts/sku/searchModel'
//查询YearRange
export const URL_api_parts_sku_searchYearRange=url+'/api/parts/sku/searchYearRange'
//查询Capacity
export const URL_api_parts_sku_searchCapacity=url+'/api/parts/sku/searchCapacity'
//手选车型数据
export const URL_api_parts_sku_searchAutoGroup=url+'/api/parts/sku/searchAutoGroup'
//搜索车型代码
export const URL_api_parts_sku_searchModelCode=url+'/api/parts/sku/searchModelCode'
//加入购物车
export const URL_shop_car=url1+'/shop_car'
//提示
export const URL_com=url1+'/tuisong'
//调货列表
export const URL_change_shop=url1+'/change_shop'
//特价
export const URL_special_price=url1+'/special_price'

// 智能EPC
export const URL_epc_brand = url2 +'/brand'
export const URL_epc_group_mapping = url2 + '/groupid_mapping'

// 智能销售
export const URL_smart_sale = url7 + '/smart_sale'
export const URL_smartepc_save = url4 + '/sale_save'

//保险直供模块
export const URL_chat = url5 + '/chat'
export const URL_risk = url5 + '/risk'
export const URL_pricing = url5 + '/pricing'

// 核价统计
export const URL_statistics =  url5 + '/statistics'

// 人员设置
export const URL_worker_manage =  url5 + '/worker_manage'

// 最新登录相关接口
export const URL_hu_new_login = url5 + '/login'
export const URL_my_setting = url5 + '/my_setting'

// 智配宝epc接口
export const URL_epc_new_brand = url6 +'/brand'
export const URL_epc_new_group_mapping = url6 + '/groupid_mapping'


export const adminDashboardUrl = url10 + '/dashboard'
export const adminLoginUrl = url10 + '/login'
export const adminRepairUrl = url10 + '/repair_shop'   //汽修地图
export const adminSettingUrl = url10 + '/my_setting'   //汽修地图
export const adminOrderToumingxiu = url10 + '/order_zhipeibao/'
export const orderToumingxiu = url10 + '/received_order_toumingxiu/search/'
export const orderToumingxiuDetail = url10 + '/received_order_toumingxiu/detail/'

// export const adminOrder = url10 + '/order/'
// export const adminOrder = url10 + '/order_yingda/query/'
// TODO 20200507 改动
export const adminOrder = url9801 + '/order_yingda/query/'

// export const URL_logistics_save = url10 + '/order_toumingxiu'
export const URL_logistics_save = url4 + '/upload/'
export const URL_zhiPeiBao_order = url10 + '/order_zhipeibao/'
export const URL_search_order = url10 + '/search/'
// export const URL_search_order = url10 + '/search/'

//url10
export const vendor_manager_show = url10 + '/vendor_manager/show/'

export const vendor_manager_user_show = url10 + '/vendor_manager/user_show/'
export const vendor_manager_user_change = url10 + '/vendor_manager/user_change/'
export const vendor_manager_user_add = url10 + '/vendor_manager/user_add/'
export const vendor_manager_user_delete = url10 + '/vendor_manager/user_delete/'
export const vendor_manager_create = url10 + '/vendor_manager/create/'
export const vendor_manager_get_makers = url10 + '/vendor_manager/get_makers/'
export const vendor_manager_delete = url10 + '/vendor_manager/delete/'
export const vendor_manager_search = url10 + '/vendor_manager/search/'
export const vendor_manager_change = url10 + '/vendor_manager/change/'
export const worker_manager_show = url10 + '/worker_manage/show/'
export const worker_manager_change = url10 + '/worker_manage/change/'
export const worker_manager_create = url10 + '/worker_manage/create/'
export const vendor_manager_punish = url10 + '/vendor_manager/punish/'
export const worker_manage_show = url10 + '/worker_manage/show/'
export const URL_tongji = url10 + '/tongji/'
export const URL_tongji1 = url10 + '/tongji/show/'
export const URL_tongji2 = url10 + '/tongji/search/'

export const jiansunInfo_tongji1 = url10 + '/tongji/show/'
export const jiansunInfo_tongji2 = url10 + '/tongji/show/'


export const banner_add= url10 + '/banner/add/';
export const banner_delete= url10 + '/banner/delete/';
export const banner_query= url10 + '/banner/query/';

/** 自动发送询价单 & 发送询价单给指定配件商 */
export const vendor_company=url10+'/enquiry_manager/vendor_company/'
/** 获取匹配的配件商*/
export const auto_send=url10+'/enquiry_manager/auto_send/'
export const send=url10+'/enquiry_manager/send/'
export const get_code=url10+'/get_code/'
export const change_password=url10+'/change_password/'


export const wuSunQuery=url10+'/wusun/query/';
export const wuSunQueryDetail=url10+'/wusun/query/detail/';
export const wuSunUpdate=url10+'/wusun/update/';